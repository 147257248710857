// Global Variables
@import './variables';

// Functions
@import './functions/index.scss';

// Mixins
@import './mixins/index.scss';

// Components
@import './components/menu.scss';
@import './components/card.scss';
@import './components/chart.scss';
@import './components/doctorProfile/index.scss';
@import './components/common/index.scss';

@import './overrides.scss';

.spinner {
	animation: icon-spin infinite 1.5s linear;
}
.btn {
  .spinner-border {
    margin-right: 10px;
  }
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.file-icon {
	display: block;
	margin-bottom: 5px;
	margin-top: 5px;

	span {
		display: inline-block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
		text-overflow: ellipsis;
		vertical-align: bottom;
	}
}

body.modal-open > :not(.modal) {
	-webkit-filter: blur(2px);
	-moz-filter: blur(2px);
	-o-filter: blur(2px);
	-ms-filter: blur(2px);
	filter: blur(2px);
}

.modal-body {
	img {
		margin-top: 30px;
	}
	textarea {
		border: solid 1px #597DA6;
    background-color: #F3F7FC;
	}
	.message {
		margin-top: 30px;
    font-weight: 500;
    margin-bottom: 30px;
		font-size: 24px;

		margin-left: 10px;
    margin-right: 10px;
	}
	.btn {
		display: block;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
	}
}

.btn-light {
	border: solid 1px #597DA6;
	color: #597DA6;
}

.doctor-specialty-list {
	span {
		&.specialty {
			&:not(:first-child) {
				&::before{
					content: ', ';
				}
				&:hover {
					color: green
				}
			}

			&:first-child {
				font-weight: 500;
			}
		}
	}

}
.footer {
	margin-top: 20px;
	font-size: 12px;
	margin-bottom: 20px;
	margin-left: 5px;
	margin-right: 5px;
}

.round {
	border-radius: 50%;
}
.image-48 {
	max-width: 48px;
  max-height: 48px;
}
.chart-tooltip-container{
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.96);
}
.box-52 {
	max-width: 52px;
  max-height: 52px;
  overflow: hidden;
  min-height: 52px;
  min-width: 52px;
}
.border-green {
	border: solid 3px $green;
}

.sidebar-layout {
	display: flex;
	padding: 0;
}

.content-body {
	padding-top: 100px;
	background-color: $background-light;
	flex: 1;
}

* {
	font-family: "Roboto";
	color: $text-color;
}
.margin-right-5 {
	margin-right: 5px;
}
.margin-bottom-25 {
	margin-bottom: 25px;
}
.margin-left-25 {
	margin-left: 25px;
}

.font-point-8 {
  font-size: 0.8em;
}

.v-center {
  vertical-align: middle;
}

.inline-block {
  display: inline-block;
}