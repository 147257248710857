.card {
  border-radius: 10px;
	@include box-shadow(0, 10px, 20px, rgba(44, 112, 190, 0.1));

  .card-header {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }
  margin-bottom: 20px;

  &.plain-header {
    .card-header, .card-footer {
	    background-color: transparent;
    }
  }

  h5, .pagination {
		margin: 0;
	}
}