
.table {
	thead {
		tr {
			background-color: #DEEBF7;
			th {
				border: none;
			}
		}
	}
	td {
		vertical-align: middle;
	}
}
/* Fixes dropdown menus placed on the right side */
.ml-auto .dropdown-menu {
	left: auto !important;
	right: 0px;
}

.table-hover {
	tbody {
		tr {
			cursor: pointer;
			&:hover {
				background-color: #F3F7FC;
			}
		}
	}
}