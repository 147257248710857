.dot {
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: inline-block;

  &-red {
    background-color: rgb(180, 0, 0);
  }
  &-green {
    animation: online-kf 2s infinite;
  }
  &-orange {
    background-color: #F9A000;
  }
  &-blue {
    background-color: rgb(0, 65, 206);
  }
}


#filter-component{
  display: flex;
  justify-content: flex-start;
}


#filter-form-row{
  margin-top: 30px;
  color: rgba(19,49,83,1)
  // margin-bottom: -20px;
}

.filter-input{
  border-radius: 10px;
  border-color: rgba(64,123,191,1);
  color: rgba(64,123,191,1);
  text-align: center;
  font-size: 19px;
  padding: 10px;
  opacity: .7;
}

#filter-date-to-label {
  margin-top: 40px;
  opacity: .7;
  color: rgba(64,123,191,1);
}

#formSpecialty {
  width: 250px;
  opacity: .7;
}

.filter-by-date {
  color: rgba(19,49,83,1)
}

#registration-label {
  font-weight: 400;
  color: rgba(19,49,83,1);
}


input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(44,112,190,1);
  opacity: 1;
  display: block;
  background: url('../../../assets/images//icons/calendar.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.filter-row{
  margin-left: 2px;
}

@keyframes online-kf {
  0% {background-color: #32D74B;}
  50% {background-color: #2bb840;}
  100% {background-color: #32D74B;}
}

.doctor-avatar {
  white-space: nowrap;
  .img-container {
    display: inline-block;
    vertical-align: middle;
  }
	span {
    white-space: nowrap;
		margin-left: 15px;
	}
}
