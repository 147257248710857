.wrapper {
	background-color: rgba(44, 112, 190, 0.1);
	border-radius: 10px;
}
.doctor-profile {
	margin: 20px;

	.doctor-profile-info-box {
		background-color: rgba(44, 112, 190, 0.1);
		padding: 20px;
		border-radius: 10px;
		margin-top: 20px;
		font-size: 14px;
	}

	.section-header {
		font-size: 18px;
		font-weight: bold;
	}
	.section-subheader {
		font-weight: 500;
	}

	.profile-image {
		float: left;
    	margin-right: 20px;
	}

	.round{
		// margin-bottom: 30px;
	}

}

.back {
	padding: 20px;
	color: rgba(19,49,83,1)
}

.category-header {
	padding: 20px;
}

.headers {
	padding-top: 20px;
	margin-bottom: -15px;
}

.info {
	padding-bottom: 20px;
}

.profile-header {
	padding-top: 20px;
	height: 28px;
	width: 171px;
}

.btn-outline-danger {
	margin-right: 10px;
}

.profile-request-buttons {
	margin: 5px;
}


.rounded{
	width: 270px;
	height: 50px;
}


.doctor-profile-specialty-files{
	display: flex;
	justify-content: flex-start;
}
