$depth: 1;
.logo {
	vertical-align: baseline;
	margin-left: 25px;
}
.navbar {
	background-color: $menu-color;
	width: 100%;
	// box-shadow: bottom-shadow($depth), top-shadow($depth);

	@include box-shadow(0, 10px, 20px, rgba(44, 112, 190, 0.1));

	.menu-bars {
		background: none;
	}
}

.nav-menu {
	background-color: $menu-color;
	padding-top: 80px;
	height: 100%;
	width: auto;
	justify-content: center;
	top: 0;
	transition: 850ms;
	font-size: 18px;

	.menu-label {
		display: none;
	}

	&.active {
		display: block;
		left: 0;
		transition: 350ms;

		.nav-menu-items {
			.menu-button {
				.menu-label {
					display: inline;
					margin-left: 16px;
				}
			}
		}
	}

	.nav-menu-items {
		width: 100%;
		padding: 0;

		.menu-button {
			padding: 0px 15px 0px 15px;
			cursor: pointer;
			list-style: none;
			text-align: left;
			vertical-align: middle;
			margin-right: 5px;
			margin-top: 15px;
			white-space: nowrap;
			a, span {
				text-decoration: none;
				display: inline-block;

				padding-top: 10px;
				padding-bottom: 10px;
				white-space: nowrap;
				width: 100%;
			}
			.menu-label {
				display: none;
			}
			border-radius: 0px 20px 20px 0px;
			&:hover {
				background-color: #1a83ff;
			}
		}
	}

	.navbar-toggle {
		background-color: orange;
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
}