.chart-title {
  top      : 15px;
  left     : 20px;

  color: $chart-stroke;
  .main-number {
    color: $chart-stroke;
    font-size   : 36px;
    font-weight : bold;
    line-height: 33px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  fontSize: 16;
}